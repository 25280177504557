<template>
  <b-row class="mb-5">
    <div class="hello12" :id="companyid" style="height: 300px"></div>
  </b-row>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
  name: "Chart",
  props: [
    "companyid",
    "serviceLineFocusData",
    "industryFocusData",
    "focusData",
    "specializationsFocusData",
    "serviceSingleChart",
  ],
  data: function () {
    return {
      selected: null,
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    setTimeout(() => {
      let focusarray = [
        this.serviceLineFocusData,
        this.industryFocusData,
        this.focusData,
        this.specializationsFocusData,
      ];
      if (this.serviceSingleChart) {
        let chart = am4core.create(this.companyid, am4charts.PieChart);

        // Add data
        chart.data = this.serviceSingleChart;

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value1";
        pieSeries.dataFields.category = "category";
        pieSeries.innerRadius = am4core.percent(50);
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;

        let rgm = new am4core.RadialGradientModifier();
        rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
        pieSeries.slices.template.fillModifier = rgm;
        pieSeries.slices.template.strokeModifier = rgm;
        pieSeries.slices.template.strokeOpacity = 0.4;
        pieSeries.slices.template.strokeWidth = 0;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";

        var colorSet1 = new am4core.ColorSet();
        colorSet1.list = [
          "#955728",
          "#434950",
          "#E9E2CB",
          "#C49F84",
          "#B4B5B7",
        ].map(function (color) {
          return new am4core.color(color);
        });
        pieSeries.colors = colorSet1;
      } else {
        // if (this.companyid == "chartdiv4") {
        for (let index = 0; index < focusarray.length; index++) {
          const element = focusarray[index];
          if (element) {
            // let id = this.companyid;
            // var chart = am4core.create(this.companyid, am4charts.PieChart);

            let chart = am4core.create(this.companyid, am4charts.PieChart);

            // Add data
            chart.data = element;

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value1";
            pieSeries.dataFields.category = "category";
            pieSeries.innerRadius = am4core.percent(50);
            pieSeries.ticks.template.disabled = true;
            pieSeries.labels.template.disabled = true;

            let rgm = new am4core.RadialGradientModifier();
            rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
            pieSeries.slices.template.fillModifier = rgm;
            pieSeries.slices.template.strokeModifier = rgm;
            pieSeries.slices.template.strokeOpacity = 0.4;
            pieSeries.slices.template.strokeWidth = 0;

            // chart.legend = new am4charts.Legend();
            // chart.legend.position = "bottom";

            var colorSet = new am4core.ColorSet();
            colorSet.list = [
              "#955728",
              "#434950",
              "#E9E2CB",
              "#C49F84",
              "#B4B5B7",
            ].map(function (color) {
              return new am4core.color(color);
            });
            pieSeries.colors = colorSet;
          }
        }
      }
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "right";
    }, 1000);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    // onSlideStart() {
    //   this.sliding = true;
    // },
    // onSlideEnd() {
    //   this.sliding = false;
    // },
  },
};
</script>
